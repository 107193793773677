import SimpleHeader from "components/Headers/SimpleHeader";
import React, { useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
import "./survey.css";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  // Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import componentStylesButtons from "assets/theme/components/button.js";
import Pagination from "rsuite/Pagination";
import Table from "react-bootstrap/Table";
import Button from "rsuite/Button";
import Input from "rsuite/Input";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosRemoveCircle } from "react-icons/io";
import Toggle from "rsuite/Toggle";
import IconButton from "@material-ui/core/IconButton";
import Form from "rsuite/Form";
import hexToRgb from "assets/theme/hex-to-rgb.js";
import { Clear } from "@material-ui/icons";
// import ButtonToolbar from "rsuite/ButtonToolbar";
import componentStylesDialogs from "assets/theme/components/dialog.js";
import { sendGet } from "axios/axiosClient";
import { sendDelete } from "axios/axiosClient";
import { sendPut } from "axios/axiosClient";
import { sendPost } from "axios/axiosClient";

const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesDialogs = makeStyles(componentStylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Survey = () => {
  const classes = {
    ...useStylesDialogs(),
    ...useStylesButtons(),
  };
  const theme = useTheme();
  const [listSurvey, setListSurvey] = useState([]);
  const [editItem, setEditItem] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [createForm, setCreateForm] = useState({
    name: "",
    coin: 0,
    link_api: "",
    note: "",
    status: 1,
    api_key: "",
    secret_key: "",
  });
  const [editForm, setEditForm] = useState({
    name: "",
    coin: 0,
    link_api: "",
    note: "",
    api_key: "",
    secret_key: "",
    status: 1,
  });
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [openAlertPrimary, setOpenAlertPrimary] = React.useState(false);
  const handleClickAlertPrimary = () => {
    setOpenAlertPrimary(true);
  };
  const handleCloseAlertPrimary = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertPrimary(false);
  };
  const [textMessage, setTextMessage] = useState("");
  const primarySnackbarRootClasses = { root: classes.primarySnackbar };
  const [totalItems, setTotalItems] = useState(0);

  const getListSurvey = async () => {
    try {
      const res = await sendGet(
        `/admin/mission/survey?page=${activePage}&page_size=${limit}`
      );
      if (res.status === 200) {
        setListSurvey(res.data.data.data);
        setTotalItems(res.data.data.meta.pagination.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListSurvey();
  }, [activePage, limit]);

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  const handleCreate = async () => {
    const input = {
      name: createForm.name,
      coin: Number(createForm?.coin),
      link_api: createForm?.link_api,
      note: createForm?.note,
      status: createForm?.status ? 1 : 0,
      api_key: createForm?.api_key,
      secret_key: createForm?.secret_key,
    };
    try {
      const res = await sendPost(`/admin/mission/survey`, input);
      if (res.status === 200) {
        await getListSurvey();
        setTextMessage("Thêm mới thành công.");
        handleClickAlertPrimary();
        handleCloseCreate();
      }
    } catch (error) {
      setTextMessage(error.response.data.msg_error);
      handleClickAlertPrimary();
    }
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/admin/mission/survey/${deleteItem}`);
      if (res.status === 200) {
        await getListSurvey();
        setTextMessage("Xoá thành công.");
        handleClickAlertPrimary();
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage(error.response.data.msg_error);
      handleClickAlertPrimary();
    }
  };

  const handleUpdateItem = async () => {
    console.log(editForm, "edit form");
    if (editForm?.name?.trim() === "") {
      setTextMessage("Tên không được bỏ trống.");
      handleClickAlertPrimary();
      return;
    }
    if (editForm?.secret_key?.trim() === "") {
      setTextMessage("Secret Key không được bỏ trống.");
      handleClickAlertPrimary();
      return;
    }
    if (editForm?.api_key?.trim() === "") {
      setTextMessage("API Key không được bỏ trống.");
      handleClickAlertPrimary();
      return;
    }
    const input = {
      name: editForm?.name,
      coin: editForm?.coin,
      link_api: editForm?.link_api,
      note: editForm?.note ? editForm?.note : "1",
      api_key: editForm?.api_key,
      secret_key: editForm?.secret_key,
      status: editForm?.status ? 1 : 0,
    };
    try {
      const res = await sendPut(`/admin/mission/survey/${editItem}`, input);
      if (res.status === 200) {
        await getListSurvey();
        setTextMessage("Cập nhật thành công.");
        handleClickAlertPrimary();
        setEditItem(0);
      }
    } catch (error) {
      setTextMessage(error.response.data.msg_error);
      handleClickAlertPrimary();
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openAlertPrimary}
        autoHideDuration={2000}
        onClose={handleCloseAlertPrimary}
        ContentProps={{
          classes: primarySnackbarRootClasses,
          elevation: 1,
        }}
        action={
          <Box
            component={IconButton}
            padding="0!important"
            onClick={handleCloseAlertPrimary}
          >
            <Box
              component="span"
              color={"rgba(" + hexToRgb(theme.palette.white.main) + ",.6)"}
            >
              ×
            </Box>
          </Box>
        }
        message={
          <>
            <Box component="span">{textMessage}</Box>
          </>
        }
      />
      <Dialog
        open={openCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCreate}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            Create new Missions Survey
          </Typography>
          <IconButton onClick={handleCloseCreate}>
            <Clear />
          </IconButton>
        </div>
        <DialogContent>
          <Form fluid formValue={createForm} onChange={setCreateForm}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control name="name" placeholder="Name" />
            </Form.Group>
            {/* <Form.Group controlId="balance">
              <Form.ControlLabel>Balance</Form.ControlLabel>
              <Form.Control name="balance" type="number" placeholder="Coin" />
            </Form.Group> */}
            <Form.Group controlId="coin">
              <Form.ControlLabel>Coin</Form.ControlLabel>
              <Form.Control name="coin" type="number" placeholder="Coin" />
            </Form.Group>
            <Form.Group controlId="link_api">
              <Form.ControlLabel>Link API</Form.ControlLabel>
              <Form.Control name="link_api" placeholder="URL" />
            </Form.Group>
            <Form.Group controlId="note">
              <Form.ControlLabel>Note</Form.ControlLabel>
              <Form.Control name="note" placeholder="Note" />
            </Form.Group>
            <Form.Group controlId="api_key">
              <Form.ControlLabel>API Key</Form.ControlLabel>
              <Form.Control name="api_key" placeholder="API Key" />
            </Form.Group>
            <Form.Group controlId="secret_key">
              <Form.ControlLabel>Secret key</Form.ControlLabel>
              <Form.Control name="secret_key" placeholder="Secret Key" />
            </Form.Group>
            {/* <Form.Group controlId="limit_per_day">
              <Form.ControlLabel>Limit Per Day</Form.ControlLabel>
              <Form.Control name="limit_per_day" type="number" />
            </Form.Group> */}
            <Form.Group controlId="status">
              <Form.ControlLabel>Status</Form.ControlLabel>
              <Form.Control name="status" accepter={Toggle} />
            </Form.Group>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreate} appearance="secondary">
            Close
          </Button>
          <Button onClick={handleCreate} appearance="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            Delete Item
          </Typography>
          <IconButton onClick={handleCloseDelete}>
            <Clear />
          </IconButton>
        </div>
        <DialogContent>
          Are you sure you want delete this survey missions ? This action can
          not redo ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} appearance="secondary">
            Close
          </Button>
          <Button onClick={handleDelete} appearance="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SimpleHeader section="Survey" subsection="Missions" />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <div className="p-2">
                <div className="d-flex justify-content-end mb-2">
                  <Button appearance="primary" onClick={handleOpenCreate}>
                    Create new Missions Survey
                  </Button>
                </div>
                <div style={{ minHeight: "60vh" }}>
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Coin</th>
                        <th>Link API</th>
                        <th>API Key</th>
                        <th>Secret Key</th>
                        <th>Note</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listSurvey?.map((item, key) => (
                        <tr key={key}>
                          <td style={{ width: "5vw", minWidth: "50px" }}>
                            {key + 1}
                          </td>
                          <td>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={item?.name}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    name: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.name
                            )}
                          </td>

                          <td style={{ width: "10vw", minWidth: "100px" }}>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={item?.coin}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    coin: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.coin
                            )}
                          </td>
                          <td style={{ maxWidth: "40vw" }}>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={item?.link_api}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    link_api: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.link_api
                            )}
                          </td>
                          <td style={{ maxWidth: "40vw" }}>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={item?.api_key}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    api_key: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.api_key
                            )}
                          </td>
                          <td style={{ maxWidth: "40vw" }}>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={item?.secret_key}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    secret_key: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.secret_key
                            )}
                          </td>
                          <td style={{ width: "10vw", minWidth: "100px" }}>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={item?.note}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    note: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.note
                            )}
                          </td>
                          <td style={{ width: "5vw", minWidth: "100px" }}>
                            {editItem === item?.id ? (
                              <Toggle
                                defaultChecked={item?.status ? true : false}
                                onChange={(checked) =>
                                  setEditForm({
                                    ...editForm,
                                    status: checked ? 1 : 0,
                                  })
                                }
                              />
                            ) : item?.status ? (
                              <AiFillCheckCircle className="text-success" />
                            ) : (
                              <IoIosRemoveCircle className="text-danger" />
                            )}
                          </td>
                          <td style={{ width: "15vw", minWidth: "200px" }}>
                            {editItem === item?.id ? (
                              <>
                                <Button
                                  appearance="link"
                                  color="blue"
                                  onClick={() => {
                                    handleUpdateItem();
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  appearance="link"
                                  color="blue"
                                  onClick={() => {
                                    setEditItem(0);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <Button
                                appearance="link"
                                color="blue"
                                onClick={() => {
                                  setEditItem(item?.id);
                                  setEditForm(item);
                                }}
                              >
                                Update
                              </Button>
                            )}
                            <Button
                              appearance="link"
                              color="red"
                              onClick={() => {
                                setDeleteItem(item?.id);
                                handleOpenDelete();
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <br />
                <Pagination
                  layout={["limit", "-", "pager"]}
                  ellipsis
                  next
                  prev
                  boundaryLinks
                  total={totalItems}
                  limit={limit}
                  // limitOptions={[1, 3]}
                  limitOptions={[10, 30]}
                  maxButtons={3}
                  activePage={activePage}
                  onChangePage={setActivePage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Survey;
