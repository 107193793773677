import SimpleHeader from "components/Headers/SimpleHeader";
import React, { useEffect, useState } from "react";
import "./analyticuser.css";
import { useTheme } from "@material-ui/core/styles";
import componentStyles from "assets/theme/views/admin/google.js";
import {
  Box,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { sendGet } from "axios/axiosClient";
import componentStylesButtons from "assets/theme/components/button.js";
import Pagination from "rsuite/Pagination";
import Table from "react-bootstrap/Table";
import Button from "rsuite/Button";
import IconButton from "@material-ui/core/IconButton";
import hexToRgb from "assets/theme/hex-to-rgb.js";
import componentStylesSnackbar from "assets/theme/components/snackbar.js";
import { Clear } from "@material-ui/icons";
import componentStylesDialogs from "assets/theme/components/dialog.js";
import { sendDelete } from "axios/axiosClient";
import Input from "rsuite/Input";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStylesDialogs = makeStyles(componentStylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const AnalyticUser = () => {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
    ...useStylesSnackbar(),
    ...useStylesDialogs(),
  };
  const primarySnackbarRootClasses = { root: classes.primarySnackbar };
  const [listData, setListData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(10);
  const [editItem, setEditItem] = useState(0);
  const [editForm, setEditForm] = useState({
    balance: "",
    day: "",
    coin: "",
    status: 1,
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const [textMessage, setTextMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState(0);
  const [createForm, setCreateForm] = useState({
    coin: 0,
    day: 0,
    status: 1,
    balance: 0,
  });
  const typeList = [
    {
      key: "withdraw",
      label: "Withdraw",
    },
    {
      key: "recharge",
      label: "Recharge",
    },
    {
      key: "open_silver_chest",
      label: "Open silver chest",
    },
    {
      key: "open_bronze_chest",
      label: "Open bronze chest",
    },
    {
      key: "open_gold_chest",
      label: "Open gold chest",
    },
    {
      key: "quay_hu",
      label: "Quay Hu",
    },
    {
      key: "lucky_number",
      label: "Lucky number",
    },
    {
      key: "complete_click_mission",
      label: "Complete click mission",
    },
    {
      key: "invite_friend_level_1",
      label: "Invite friend level 1",
    },
    {
      key: "invite_friend_level_2",
      label: "Invite friend level 2",
    },
    {
      key: "invite_friend_level_3",
      label: "Invite friend level 3",
    },
    {
      key: "complete_survey_mission",
      label: "Complete survey mission",
    },
    {
      key: "download_app",
      label: "Download app",
    },
    {
      key: "attendance",
      label: "Attendance",
    },
  ];
  const [search, setSearch] = useState("");

  const [openAlertPrimary, setOpenAlertPrimary] = React.useState(false);
  const handleClickAlertPrimary = () => {
    setOpenAlertPrimary(true);
  };
  const handleCloseAlertPrimary = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertPrimary(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getAnalyticUserList();
    }, 500);
    return () => clearTimeout(timer);
  }, [limit, activePage, search]);

  const getAnalyticUserList = async () => {
    try {
      const input = {
        page: activePage,
        page_size: limit,
      };
      if (search !== "") {
        input.search = search;
      }
      const res = await sendGet(`/admin/analytic-users`, input);
      if (res.status === 200) {
        setListData(res.data.data.data);
        setTotalItems(res.data.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeLimit = (e) => {
    setActivePage(1);
    setLimit(e);
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`admin/analytic-users/${deleteItem}`);
      if (res.status === 200) {
        await getAnalyticUserList();
        setTextMessage("Xoá thành công.");
        handleClickAlertPrimary();
      }
    } catch (error) {
      setTextMessage(error.response.data.msg_error);
      handleClickAlertPrimary();
    }
    handleClose();
  };

  const findType = (type) => {
    const found = typeList.find((item) => item.key === type);
    return found.label;
  };

  const handleSearch = (e) => {
    setSearch(e);
    setActivePage(1);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openAlertPrimary}
        autoHideDuration={2000}
        onClose={handleCloseAlertPrimary}
        ContentProps={{
          classes: primarySnackbarRootClasses,
          elevation: 1,
        }}
        action={
          <Box
            component={IconButton}
            padding="0!important"
            onClick={handleCloseAlertPrimary}
          >
            <Box
              component="span"
              color={"rgba(" + hexToRgb(theme.palette.white.main) + ",.6)"}
            >
              ×
            </Box>
          </Box>
        }
        message={
          <>
            <Box component="span">{textMessage}</Box>
          </>
        }
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            Delete item
          </Typography>
          <IconButton onClick={handleClose}>
            <Clear />
          </IconButton>
        </div>
        <DialogContent>
          Are you sure you want delete this transaction ? This action can not
          redo ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} appearance="secondary">
            Close
          </Button>
          <Button onClick={handleDelete} appearance="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <SimpleHeader section="Daily" subsection="Missions" />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <div className="p-2">
                <div className="d-flex justify-content-end mb-3">
                  {/* <Button appearance="primary" onClick={handleOpenCreate}>
                    Create new Transaction
                  </Button> */}
                  <Input
                    style={{ width: "10%", minWidth: "260px" }}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                <div style={{ minHeight: "60vh" }}>
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>User ID</th>
                        <th>Service ID</th>
                        <th>Balance</th>
                        <th>Coin</th>
                        <th>Service</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData?.map((item, key) => (
                        <tr key={key}>
                          <td style={{ width: "5vw", minWidth: "50px" }}>
                            {key + 1}
                          </td>
                          <td>{findType(item?.type)}</td>
                          <td>{item?.user_id}</td>
                          <td>{item?.service_id}</td>
                          <td>{item?.balance}</td>
                          <td>{item?.coin}</td>
                          <td className="serviceTb">
                            {item?.service?.balance ? (
                              <p>Balance: {item?.service?.balance}</p>
                            ) : (
                              <></>
                            )}
                            {item?.service?.coin ? (
                              <p>Coin: {item?.service?.coin}</p>
                            ) : (
                              <></>
                            )}
                            {item?.service?.name ? (
                              <p>Name: {item?.service?.name}</p>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td style={{ width: "7vw", minWidth: "100px" }}>
                            <Button
                              appearance="link"
                              color="red"
                              onClick={() => {
                                setDeleteItem(item?.id);
                                handleOpen();
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <br />
                <Pagination
                  layout={["limit", "-", "pager"]}
                  ellipsis
                  next
                  prev
                  boundaryLinks
                  total={totalItems}
                  limit={limit}
                  // limitOptions={[1, 3]}
                  limitOptions={[10, 30]}
                  maxButtons={3}
                  activePage={activePage}
                  onChangePage={setActivePage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AnalyticUser;
