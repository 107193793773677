import SimpleHeader from "components/Headers/SimpleHeader";
import React, { useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
import "./joins.css";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useTheme } from "@material-ui/core/styles";
import componentStyles from "assets/theme/views/admin/google.js";
import {
  Box,
  // Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import componentStylesButtons from "assets/theme/components/button.js";
import Pagination from "rsuite/Pagination";
import Table from "react-bootstrap/Table";
import Button from "rsuite/Button";
import Input from "rsuite/Input";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosAddCircleOutline, IoIosRemoveCircle } from "react-icons/io";
import Toggle from "rsuite/Toggle";
import IconButton from "@material-ui/core/IconButton";
import Form from "rsuite/Form";
import hexToRgb from "assets/theme/hex-to-rgb.js";
import componentStylesSnackbar from "assets/theme/components/snackbar.js";
import { Clear } from "@material-ui/icons";
// import ButtonToolbar from "rsuite/ButtonToolbar";
import componentStylesDialogs from "assets/theme/components/dialog.js";
import { sendGet } from "axios/axiosClient";
import { sendDelete } from "axios/axiosClient";
import Radio from "rsuite/Radio";
import RadioGroup from "rsuite/RadioGroup";
import { sendPost } from "axios/axiosClient";
import InputNumber from "rsuite/InputNumber";
// import { sendPost } from "axios/axiosClient";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesSnackbar = makeStyles(componentStylesSnackbar);
const useStylesDialogs = makeStyles(componentStylesDialogs);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Joins = () => {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
    ...useStylesSnackbar(),
    ...useStylesDialogs(),
  };
  const primarySnackbarRootClasses = { root: classes.primarySnackbar };
  const [listData, setListData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(10);
  const [editItem, setEditItem] = useState(0);
  const [editForm, setEditForm] = useState({
    coin: 0,
    balance: 0,
    type: 0,
    limit: 0,
    status: 1,
  });
  const [listImages, setListImages] = useState([0]);
  const theme = useTheme();
  const [textMessage, setTextMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState(0);
  const [createForm, setCreateForm] = useState({
    name: "",
    coin: 0,
    balance: 0,
    type: 1,
    limit: 0,
    status: 1,
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [openAlertPrimary, setOpenAlertPrimary] = React.useState(false);
  const handleClickAlertPrimary = () => {
    setOpenAlertPrimary(true);
  };
  const handleCloseAlertPrimary = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertPrimary(false);
  };
  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };
  const [objectNum, setObjectNum] = useState(1);
  const handleObjectNum = () => {
    const plus = objectNum + 1;
    setObjectNum(plus);
  };

  useEffect(() => {
    console.log(objectNum, "obj num");
  }, [objectNum]);

  const handleCreateJoins = async () => {
    if (createForm?.name === "") {
      setTextMessage("The name field is required.");
      handleClickAlertPrimary();
      return;
    }
    const formData = new FormData();
    formData.append("name", createForm?.name);
    formData.append("balance", createForm?.balance);
    formData.append("coin", createForm?.coin);
    formData.append("type", createForm?.type);
    formData.append("status", createForm?.status);
    formData.append("limit", createForm?.limit);
    for (let index = 0; index < objectNum; index++) {
      formData.append(`object[${index}][id]`, createForm?.[`id[${index}]`]);
      formData.append(`object[${index}][type]`, createForm?.[`type[${index}]`]);
    }
    try {
      const res = await sendPost(`/admin/mission/join`, formData);
      if (res.status === 200) {
        await getListJoins();
        setTextMessage("Thêm mới Joins thành công.");
        handleClickAlertPrimary();
      }
    } catch (error) {
      setTextMessage(error.response.data.msg_error);
      handleClickAlertPrimary();
    }
    handleCloseCreate();
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/admin/mission/join/${deleteItem}`);
      if (res.status === 200) {
        await getListJoins();
        setTextMessage("Xoá thành công.");
        handleClickAlertPrimary();
      }
    } catch (error) {
      setTextMessage(error.response.data.msg_error);
      handleClickAlertPrimary();
    }
    handleClose();
  };

  const handleUpdateItem = async () => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("name", editForm?.name);
    formData.append("balance", editForm?.balance);
    formData.append("coin", editForm?.coin);
    formData.append("type", editForm?.type);
    formData.append("status", editForm?.status);
    formData.append("limit", editForm?.limit);
    for (let index = 0; index < editForm?.object?.length; index++) {
      formData.append(`object[${index}][id]`, editForm?.object[index]?.id);
      formData.append(`object[${index}][type]`, editForm?.object[index]?.type);
    }
    try {
      const res = await sendPost(`/admin/mission/join/${editItem}`, formData);
      if (res.status === 200) {
        await getListJoins();
        setTextMessage("Cập nhật thành công.");
        handleClickAlertPrimary();
      }
    } catch (error) {
      setTextMessage(error.response.data.msg_error);
      handleClickAlertPrimary();
    }
    setEditItem(0);
  };

  useEffect(() => {
    getListJoins();
  }, [activePage, limit]);

  const getListJoins = async () => {
    try {
      const res = await sendGet(`/admin/mission/join`);
      if (res.status === 200) {
        setListData(res.data.data.data);
        setTotalItems(res.data.data.meta.pagination.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateItem = (itemIndex, updateValue, prop) => {
    // Tạo một bản sao của đối tượng data
    const newData = { ...editForm };

    // Tìm phần tử cần cập nhật trong mảng và thay đổi name
    newData.object = newData.object.map((item, index) => {
      switch (prop) {
        case "id":
          if (index === itemIndex) {
            return { ...item, id: updateValue };
          }
          break;
        case "type":
          if (index === itemIndex) {
            return { ...item, type: updateValue };
          }
          break;
        default:
          break;
      }
      return item;
    });
    // Cập nhật đối tượng data với bản sao đã thay đổi
    setEditForm(newData);
  };

  const removeItem = (itemIndex) => {
    // Tạo một bản sao của đối tượng data
    const newData = { ...editForm };
    console.log("delete index", itemIndex);

    // Sử dụng filter để tạo một mảng mới không bao gồm đối tượng cần xóa
    newData.object = newData.object.filter(
      (item, index) => index !== itemIndex
    );

    // Cập nhật đối tượng data gốc với bản sao đã thay đổi
    // console.log(newData);
    setEditForm(newData);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openAlertPrimary}
        autoHideDuration={2000}
        onClose={handleCloseAlertPrimary}
        ContentProps={{
          classes: primarySnackbarRootClasses,
          elevation: 1,
        }}
        action={
          <Box
            component={IconButton}
            padding="0!important"
            onClick={handleCloseAlertPrimary}
          >
            <Box
              component="span"
              color={"rgba(" + hexToRgb(theme.palette.white.main) + ",.6)"}
            >
              ×
            </Box>
          </Box>
        }
        message={
          <>
            <Box component="span">{textMessage}</Box>
          </>
        }
      />
      <Dialog
        open={openCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCreate}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            Create new Joins Daily
          </Typography>
          <IconButton onClick={handleCloseCreate}>
            <Clear />
          </IconButton>
        </div>
        <DialogContent>
          <Form fluid formValue={createForm} onChange={setCreateForm}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group controlId="balance">
              <Form.ControlLabel>Balance</Form.ControlLabel>
              <Form.Control name="balance" accepter={InputNumber} />
            </Form.Group>
            <Form.Group controlId="coin">
              <Form.ControlLabel>Coin</Form.ControlLabel>
              <Form.Control name="coin" accepter={InputNumber} />
            </Form.Group>
            <Form.Group controlId="limit">
              <Form.ControlLabel>Limit</Form.ControlLabel>
              <Form.Control name="limit" accepter={InputNumber} />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>Type</Form.ControlLabel>
              <Form.Control name="type" accepter={RadioGroup} inline>
                <Radio value={1}>Join group before start bot</Radio>
                <Radio value={2}>Mission join group/channel</Radio>
              </Form.Control>
            </Form.Group>
            {listImages.map((_, index) => (
              <div className="d-flex gap-2 justify-content-between align-items-center">
                <Form.Group controlId={`object ${index}`} key={index}>
                  <Form.ControlLabel>Object {index}</Form.ControlLabel>
                  <Form.Control placeholder="ID" name={`id[${index}]`} />
                </Form.Group>
                <Form.Group controlId={`object ${index}`} key={index}>
                  <Form.ControlLabel>Type</Form.ControlLabel>
                  <Form.Control placeholder="Type" name={`type[${index}]`} />
                </Form.Group>
                <Button
                  style={{ height: "2rem" }}
                  onClick={() => listImages.splice(index, 1)}
                >
                  x
                </Button>
              </div>
            ))}
            <div className="text-center">
              <Button onClick={() => handleObjectNum()}>
                <IoIosAddCircleOutline />
              </Button>
            </div>
            <Form.Group controlId="status">
              <Form.ControlLabel>Status</Form.ControlLabel>
              <Form.Control
                name="status"
                accepter={Toggle}
                defaultValue={true}
              />
            </Form.Group>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreate} appearance="secondary">
            Close
          </Button>
          <Button onClick={handleCreateJoins} appearance="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            Delete item
          </Typography>
          <IconButton onClick={handleClose}>
            <Clear />
          </IconButton>
        </div>
        <DialogContent>
          Are you sure you want delete this daily missions ? This action can not
          redo ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} appearance="secondary">
            Close
          </Button>
          <Button onClick={handleDelete} appearance="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <SimpleHeader section="Daily" subsection="Missions" />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <div className="p-2">
                <div className="d-flex justify-content-end mb-2">
                  <Button appearance="primary" onClick={handleOpenCreate}>
                    Create new Joins Missions
                  </Button>
                </div>
                <div style={{ minHeight: "60vh" }}>
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Object</th>
                        <th>Balance</th>
                        <th>Coin</th>
                        <th>Limit</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData?.map((item, key) => (
                        <tr key={key}>
                          <td style={{ width: "5vw", minWidth: "50px" }}>
                            {key + 1}
                          </td>
                          <td>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={editForm?.name}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    name: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.name
                            )}
                          </td>
                          <td>
                            {editItem === item?.id ? (
                              <>
                                {editForm?.object?.map((obj, k) => (
                                  <div className="d-flex mb-1" key={k}>
                                    <Input
                                      placeholder="ID"
                                      value={obj?.id}
                                      onChange={(e) => {
                                        updateItem(k, e, "id");
                                      }}
                                    ></Input>
                                    <Input
                                      placeholder="Type"
                                      style={{ marginLeft: ".5rem" }}
                                      value={obj?.type}
                                      onChange={(e) => {
                                        updateItem(k, e, "type");
                                      }}
                                    ></Input>
                                    <Button
                                      className="px-3"
                                      style={{ marginLeft: ".5rem" }}
                                      onClick={
                                        () => {
                                          removeItem(k);
                                        }

                                        // setEditForm({
                                        //   ...editForm,
                                        //   object: editForm?.object?.filter(
                                        //     (dlt, index) => index !== k
                                        //   ),
                                        // })
                                      }
                                    >
                                      x
                                    </Button>
                                  </div>
                                ))}
                                <div className="text-center mt-2">
                                  <Button
                                    onClick={() => {
                                      const addData = {
                                        ...editForm,
                                        object: [
                                          ...editForm.object,
                                          { id: "", type: "" },
                                        ],
                                      };
                                      setEditForm(addData);
                                    }}
                                  >
                                    <IoIosAddCircleOutline />
                                  </Button>
                                </div>
                              </>
                            ) : (
                              item?.object?.map((obj, key) => (
                                <p key={key}>
                                  {obj.id} - {obj.type}
                                </p>
                              ))
                            )}
                          </td>
                          <td>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={item?.balance}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    balance: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.balance
                            )}
                          </td>
                          <td>
                            {editItem === item?.id ? (
                              <Input
                                defaultValue={editForm?.coin}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    coin: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.coin
                            )}
                          </td>
                          <td>
                            {editItem === item?.id ? (
                              <Input
                                value={Number(editForm?.limit)}
                                onChange={(e) => {
                                  setEditForm({
                                    ...editForm,
                                    limit: e,
                                  });
                                }}
                              ></Input>
                            ) : (
                              item?.limit
                            )}
                          </td>

                          <td style={{ width: "15vw", minWidth: "100px" }}>
                            {editItem === item?.id ? (
                              <Toggle
                                defaultChecked={item?.status ? true : false}
                                onChange={(checked) =>
                                  setEditForm({
                                    ...editForm,
                                    status: checked ? 1 : 0,
                                  })
                                }
                              />
                            ) : item?.status ? (
                              <AiFillCheckCircle className="text-success" />
                            ) : (
                              <IoIosRemoveCircle className="text-danger" />
                            )}
                          </td>
                          <td style={{ width: "15vw", minWidth: "200px" }}>
                            {editItem === item?.id ? (
                              <>
                                <Button
                                  appearance="link"
                                  color="blue"
                                  onClick={() => {
                                    handleUpdateItem();
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  appearance="link"
                                  color="blue"
                                  onClick={() => {
                                    setEditItem(0);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <Button
                                appearance="link"
                                color="blue"
                                onClick={() => {
                                  setEditItem(item?.id);
                                  setEditForm(item);
                                }}
                              >
                                Update
                              </Button>
                            )}

                            <Button
                              appearance="link"
                              color="red"
                              onClick={() => {
                                setDeleteItem(item?.id);
                                handleOpen();
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <br />
                <Pagination
                  layout={["limit", "-", "pager"]}
                  ellipsis
                  next
                  prev
                  boundaryLinks
                  total={totalItems}
                  limit={limit}
                  limitOptions={[10, 30]}
                  maxButtons={3}
                  activePage={activePage}
                  onChangePage={setActivePage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Joins;
