import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { getLoginStateFailed } from "../redux/userSlice";
// import { LinkContext } from "../context/createLinkProvider";
const axiosClient = axios.create({
  //   baseURL: 'process.env.REACT_APP_API_URL',
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Content-type': 'application/json',
    Authorization: "Bearer " + Cookies.get("token"),
  },
  paramsSerializer: {
    serialize: (params) => {
      return new URLSearchParams(params).toString();
    },
  },
});

const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  // const dispatch = useDispatch();
  // const { setIsAuthen } = useContext(LinkContext);
  useEffect(() => {
    const interceptor = axiosClient.interceptors.request.use(async (config) => {
      const token = await Cookies.get("token");
      // const token = await window.localStorage.getItem("token");
      // const token = await Cookies.get("token");

      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    const interceptor2 = axiosClient.interceptors.response.use(
      (response) => {
        if (response && response.data) {
          return response;
        }
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          console.log("You don't have permission to go to this path");
          // setIsAuthen(false);
          Cookies.remove("token");
          if (Cookies.get("admin") !== undefined) {
            Cookies.remove("admin");
          }
          window.location.assign(window.location.origin + "/auth/login");
          // navigate("/login");
          // console.log("go to login");
          // window.localStorage.removeItem("authUsername");
          // dispatch(getLoginStateFailed());
        } else if (error.code == "ERR_NETWORK") {
          // setIsAuthen(false);
          // navigate("/login");
          window.location.assign(window.location.origin + "/login");

          // console.log("go to login");
        }

        throw error;
      }
    );
    setIsSet(true);
    return () =>
      axiosClient.interceptors.response.eject(interceptor, interceptor2);
  }, []);

  return isSet && children;
};
export const sendGet = (url, params) => axiosClient.get(url, { params });
export const sendPost = (url, params, queryParams) =>
  axiosClient.post(url, params, { params: queryParams });
export const sendPut = (url, params) => axiosClient.put(url, params);
export const sendPatch = (url, params) => axiosClient.patch(url, params);
export const sendDelete = (url, params) => axiosClient.delete(url, params);
export default axiosClient;
export { AxiosInterceptor };
